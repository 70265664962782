<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Public Review Comments</h3>

	<p>When a framework is <span v-html="link('public_review_settings', 'open for Public Review')"></span>, anyone can leave comments for framework editors about the overall document (framework) or any item (e.g. a standard) in the framework, without needing to sign in to <span v-html="app_name"/>.</p>

    <img srcset="/docimages/comment_feature.png 1.5x" class="k-help-img float-right">
	<p class="k-no-clear">
	If the framework you’re viewing is open for Public Review, you will see a pink banner with a message similar to the one shown at right when viewing any item in the framework. To leave a comment:
	<ul>
		<li>Enable comments, if necessary, by clicking the <v-icon small>fa-solid fa-comment</v-icon> icon.</li>
		<li>Click the <img srcset="/docimages/public_review.png 4x" class="k-help-img"> button from an item or document card.</li>
		<li>Enter your name in the text box (1), or leave blank to post your review anonymously.</li>
		<li>Enter your comment (2), then click SAVE (3) to post the comment for review.</li>
	</ul>
	</p>
	<p class="k-no-clear"><b>IMPORTANT:</b> public review comments can <i>only</i> be seen by users with Framework Admin or Editor rights.</p>

	<p class="k-no-clear">To learn more about <span v-html="link('add_comments', 'Comments')"></span> or <span v-html="link('suggest_edits', 'Suggesting Edits')"></span>, review our User Guides.</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	